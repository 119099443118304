<template>
  <div v-loading="loaders.coordinatorSubCategories" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Sub Category 1</h1>
      <base-button class="btn-secondary-action title-btn-padding button-font" @click="toggleModal('Save')">
        Add New
      </base-button>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.coordinatorSubCategories"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.coordinatorSubCategories"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.coordinatorSubCategories"
        >Deactivate
        </base-button>
      </div>
        <el-table
          class="table-responsive table-flush"
          header-row-class-name="thead-light"
          width="100%"
          ref="multipleSelectionTable"
          :data="response.coordinatorSubCategories"
          @selection-change="coordinatorSubCategoriesMultiSelect"
        >
          <el-table-column prop="selected" type="selection" min-width="130px">
          </el-table-column>
          <el-table-column label="Sub Category 1" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Category" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                {{ (scope.row.coordinator_category) ? scope.row.coordinator_category.name : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status" min-width="130px">
            <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS">
            <div slot-scope="{ $index, row }" class="d-flex">
              <img
                class="pointer"
                @click="toggleModal('Update' , row)"
                src="/img/icons/buttons/edit.svg"
                alt="Edit"
              />
              <img
                class="pointer ml-2"
                @click="handleDelete(row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      <div class="col-12 d-flex justify-content-end flex-wrap">
        <custom-pagination
          class="mt-4 pagination-no-border float-right"
          v-model="request.pagination.page"
          :per-page="request.pagination.perPage"
          :total="request.pagination.total"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeCoordinatorSubCategory"
        @onRemoveMultiple="deleteCoordinatorSubCategories"
      />
      <el-dialog
        :title="modal.title"
        :visible.sync="modals.CoordinatorSubCategoryModal"
        width="30%"
        :before-close="handleClose"
        :close-on-click-modal="false">
          <el-form role="form" :model="coordinatorSubCategory" ref="formCoordinatorSubCategory" :name="modal.formType" >
            <div class="mt--4">
              <label for="">Select Category*</label>
              <el-form-item prop="coordinatorCategory" :rules="rules.coordinatorCategory">
                <el-select
                  class="w-100"
                  label="Category"
                  :rules="{required: true}"
                  v-model="coordinatorSubCategory.coordinatorCategory">
                  <el-option
                    v-for="option in response.coordinatorCategories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <label for="">Sub Category 1*</label>
              <el-form-item prop="name" :rules="rules.name">
                <el-input
                  class=""
                  name="Category Name"
                  v-model="coordinatorSubCategory.name"
                  placeholder="Category Name">
                </el-input>
              </el-form-item>
              <label for="">Status*</label>
              <el-form-item prop="status" :rules="rules.status">
                <el-select
                  class="w-100"
                  v-model="coordinatorSubCategory.status">
                  <el-option
                    v-for="option in dropdowns.status"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="text-right mt-4">
              <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
              <base-button class="btn-primary-action pl-5 pr-5 br-4" @click="onFormSubmit('formCoordinatorSubCategory')" type="success">{{modal.buttonText}}</base-button>
            </div>
          </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import BackButton from "@/components/Router/BackButton";
import CustomPagination from "../../../Components/Pagination/CustomPagination";


const RemoveModal = () => import(/* webpackChunkName: "coordinatorSubCategoriesRemoveModal" */ "@/components/Modals/RemoveModal.vue");

export default {
  name: "CoordinatorSubCategories",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,BackButton,CustomPagination
  },
  mounted() {
    this.fetchCoordinatorSubCategories()
  },
  data() {
    return {
      loaders: {
        coordinatorSubCategories: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      response: {
        coordinatorCategories: [],
        coordinatorSubCategories: [],
      },
      modals : {
        CoordinatorSubCategoryModal : false,
      },
      modal : {
        title : 'Add Sub Category 1',
        buttonText : 'Save',
        formType: 'Save',
      },
      coordinatorSubCategory : {
        id : '',
        name : '',
        status : 1,
        coordinatorCategory : '',
      },
      selectedCoordinatorSubCategory : '',
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
      rules: {
        name: [
          {required: true, message: 'Please input Sub Category Name', trigger: 'blur'},
          {min: 3, max: 55, message: 'Length should be 3 to 55', trigger: 'blur'}
        ],
        status: [
          {required: true, message: 'Please select status', trigger: 'change'},
        ],
        coordinatorCategory: [
          {required: true, message: 'Please select Category', trigger: 'change'},
        ],
      },
      request : {
        pagination : {
          page:1,
          currentPage:1,
          perPage:20,
          total:1,
        }
      },
    };
  },
  methods: {

    fetchCoordinatorSubCategories() {
      let vm = this;
      vm.loaders.coordinatorSubCategories = true
      this.clearSelection()
      axios.get(this.$store.getters.getBaseUrl + '/api/content/coordinator-sub-categories?page='+vm.request.pagination.page)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.coordinatorSubCategories = response.data.data.data;
          vm.request.pagination.page = response.data.data.current_page;
          vm.request.pagination.perPage = response.data.data.per_page;
          vm.request.pagination.total = response.data.data.total;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.coordinatorSubCategories = false);
    },

    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    getStatus(status) {
      if (status === 0) {
        return 'Inactive'
      }
      return 'Active';
    },

    coordinatorSubCategoriesMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    clearSelection() {
      this.$refs.multipleSelectionTable.clearSelection();
    },

    statusUpdateRequest(ids, status) {
      this.loaders.coordinatorSubCategories = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/coordinator-sub-categories/toggle-status`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = []
          this.fetchCoordinatorSubCategories()
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.coordinatorSubCategories = false
        })
    },

    handleDelete(row) {
      this.promptRemove(row)
    },

    promptRemove(row) {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Coordinator Category.\n' +
        'Deleting Sub Category will remove all Coordinator info and Sub Categories 2 associated with it.';
      this.removeId = row.id;
    },

    promptMultiRemove() {
      this.$store.commit("showRemoveModal");
      this.removeModalText = 'You want to remove this Coordinator Categories.\n' +
        'Deleting Sub Category will remove all Coordinator info and Sub Categories 2 associated with it.';
      this.removeId = this.extractSelectedIds;
    },

    removeCoordinatorSubCategory(id) {
      this.deleteRequest(id)
    },

    deleteCoordinatorSubCategories() {
      this.deleteRequest(this.removeId)
    },

    deleteRequest(data) {
      this.loaders.coordinatorSubCategories = true
      this.loaders.checkboxButton.Delete = true
      const payload = { ids: Array.isArray(data) ? data : [data] };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/coordinator-sub-categories/destroy`, payload)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.removeId = [];
          this.fetchCoordinatorSubCategories();
          this.$store.commit("hideRemoveModal");
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.checkboxButton.Delete = false;
          this.loaders.coordinatorSubCategories = false;
        })
    },


    toggleModal(modalType ,row){
      console.log(modalType,row);
      if(modalType==='Save'){
        this.modal.title = 'Add Sub Category 1';
        this.modal.buttonText = 'Save';
        this.modal.formType = 'Save';
      } else if(modalType==='Update'){
        this.modal.title = 'Update Sub Category 1';
        this.modal.buttonText = 'Update';
        this.modal.formType = 'Update';
        this.selectedCoordinatorSubCategory = row.id;
        this.fetchCoordinatorSubCategory();
      }
      this.fetchCoordinatorCategories();
      this.modals.CoordinatorSubCategoryModal = !this.modals.CoordinatorSubCategoryModal;
    },

    fetchCoordinatorSubCategory() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/content/coordinator-sub-categories/' + this.selectedCoordinatorSubCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.coordinatorSubCategory.id = response.data.data.id;
          vm.coordinatorSubCategory.name = response.data.data.name;
          vm.coordinatorSubCategory.status = response.data.data.status;
          vm.coordinatorSubCategory.coordinatorCategory = response.data.data.coordinator_category_id;
          console.log(vm.coordinatorSubCategory);
        })
        .catch((error) => console.error(error.response));
    },

    fetchCoordinatorCategories() {
      let vm = this;
      vm.loaders.coordinatorSubCategories = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/coordinator-categories/all')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.coordinatorCategories = response.data.data;
        })
        .catch((error) => console.error(error.response))
        .finally(() => vm.loaders.coordinatorSubCategories = false);
    },

    async onFormSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
            if (this.$refs.formCoordinatorSubCategory.$attrs.name === 'Save') {
              this.storeCoordinatorSubCategory();
            } else if (this.$refs.formCoordinatorSubCategory.$attrs.name === 'Update') {
              this.updateCoordinatorSubCategory();
            }
        } else {
            this.$notify.error({
              title: 'Validation Error',
              message: 'Please check values in the form'
            });
          return false;
        }
      });
    },

    storeCoordinatorSubCategory() {
      let vm = this;
      vm.loaders.coordinatorSubCategories = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/content/coordinator-sub-categories/store`, vm.coordinatorSubCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.handleClose();
          this.fetchCoordinatorSubCategories();
          this.modals.CoordinatorSubCategoryModal = false;
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.coordinatorSubCategories = false
        })
    },

    updateCoordinatorSubCategory() {
      let vm = this;
      vm.loaders.coordinatorSubCategories = true;
      axios.post(this.$store.getters.getBaseUrl + `/api/content/coordinator-sub-categories/update`, vm.coordinatorSubCategory)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.handleClose();
          this.fetchCoordinatorSubCategories();
          this.modals.CoordinatorSubCategoryModal = false;
        })
        .catch((error) => console.error(error.message))
        .finally(()=> {
          this.loaders.coordinatorSubCategories = false
        })
    },

    handleClose(){
      this.clearCoordinatorCategoryForm();
      this.$refs.formCoordinatorSubCategory.resetFields();
      this.modals.CoordinatorSubCategoryModal = false;
    },

    clearCoordinatorCategoryForm() {
      this.coordinatorSubCategory.id = '';
      this.coordinatorSubCategory.name = '';
      this.coordinatorSubCategory.status = 1;
      this.coordinatorSubCategory.coordinatorCategory = '';

      this.modal.title = '';
      this.modal.buttonText = '';
      this.modal.formType = '';
      this.selectedCoordinatorSubCategory = '';
    },
    changePage(page){
      if(page != this.request.pagination.page ){
        this.request.pagination.page = page;
        this.fetchCoordinatorSubCategories();
      }
    },
  },
  computed: {
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  }
}
</script>

<style scoped>

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

</style>
